import React, {Fragment} from 'react';
import TextWrapper from '../theme/TextWrapper';
import styled from 'styled-components';
import {
  COLOR_TEXT_DARK,
  COLOR_TEXT_LIGHT,
  MAX_MOBILE_WIDTH,
} from '../../constants/styles';

const HeadingText = styled(TextWrapper)`
  font-weight: 800;
  font-size: 1.7em;
  padding-bottom: 7px;
  color: ${props => props.isDark ? COLOR_TEXT_DARK : COLOR_TEXT_LIGHT};
`;

export default HeadingText;