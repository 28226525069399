import styled from 'styled-components';
import HeadingText from './text/HeadingText';
import {COLOR_PRIMARY, COLOR_TEXT_DARK} from '../constants/styles';
import ViewWrapper from './theme/ViewWrapper';
import TextWrapper from './theme/TextWrapper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';

export const DetailsInfoBlock = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${COLOR_TEXT_DARK};
  padding: 10px 0;
  width: 100%;
  font-size: 1em;
  min-height: max-content;
`;

export const DetailsHeading = styled(HeadingText)`
  color: ${COLOR_TEXT_DARK};
`;

const DetailIconWrapper = styled(ViewWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  width: 100%;
  color: ${COLOR_TEXT_DARK};
  padding-bottom: 5px;
`;

export const DetailsInfoTitle = (props: {icon: any, title: string}) => {
  return (
      <DetailIconWrapper>
        {props.icon && <FontAwesomeIcon icon={props.icon} color={COLOR_PRIMARY} style={{width: "40px"}}/>}
        <TextWrapper style={{paddingLeft: "10px", fontSize: "1.1em", fontWeight: 700}}>{props.title}</TextWrapper>
      </DetailIconWrapper>
  )
};

export const DetailsInfo = styled(TextWrapper)`
  padding-left: 50px;
`;