import ViewWrapper from './theme/ViewWrapper';
import React, { Fragment, useContext, useState } from 'react';
import {
  COLOR_PRIMARY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_LIGHT,
} from '../constants/styles';
import styled from 'styled-components';
import routes from '../constants/routes';
import { useHistory } from 'react-router';
import TextWrapper from './theme/TextWrapper';
import { APP_ENVIRONMENT } from '../config/appConfig';

type Props = {
  title: string,
};

function Header(props: Props) {
  const history = useHistory();

  return (
    <Fragment>
      <TopHeader>
        {APP_ENVIRONMENT !== 'production' && (
          <TextWrapper
            style={{
              width: '100%',
              color: 'white',
              textTransform: 'uppercase',
              fontWeight: 800,
              textAlign: 'center',
              backgroundColor: '#59cb34',
            }}>
            {APP_ENVIRONMENT}
          </TextWrapper>
        )}
        <NavBar >
          <Title>{props.title}</Title>
        </NavBar>
      </TopHeader>
    </Fragment>
  );
}

const Title = styled(ViewWrapper)`
  color: white;
  font-weight: 800;
  font-size: 1.7em;
  padding-bottom: 7px;
  z-index: 10;
  text-align: right;
`;

const Logo = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  align-items: center;
`;

const TopHeader = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${COLOR_PRIMARY};
`;

const NavBar = styled(ViewWrapper)`
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const NavBarMenuIcon = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const DropdownMenu = styled(ViewWrapper)`
  height: 100%;
  background-color: white;
  width: 75%;
  max-width: 500px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.3) -10px 0 10px -1px;
  padding: 10px 20px 50px;
  overflow-y: scroll;
`;

const DropdownItemWrapper = styled(ViewWrapper)`
  font-size: 1em;
  font-weight: 600;
  padding: 10px 0;
  color: ${COLOR_TEXT_DARK};
  border-bottom-style: solid;
  border-bottom-color: #e1e1e1;
  border-bottom-width: 1px;
`;

export default Header;
