import React, { Fragment } from 'react';
import styled from 'styled-components';
import TextWrapper from '../theme/TextWrapper';
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
  MAX_MOBILE_WIDTH,
} from '../../constants/styles';

const PrimaryButton = styled.button`
  border-radius: 10px;
  border-style: solid;
  border-color: ${props => props.disabled ? '#e1e1e1' : 'white'};;
  background-color: ${props => props.disabled ? '#e1e1e1' : 'white'};
  padding: 10px 6px;
  width: 100%;
  margin-bottom: 10px;
  min-height: 50px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 5px;
  font-size: 1.3em;
  font-weight: 800;
  color: ${props => props.disabled ? COLOR_PRIMARY_LIGHT : COLOR_PRIMARY};
  color: ${COLOR_PRIMARY};
  
  &:focus, &:hover {
    color: ${COLOR_PRIMARY_LIGHT};
    background-color: #fafafa;
    border-color: #fafafa;
  }
  
  &:after{
    color: ${COLOR_PRIMARY};
  border-color: ${props => props.disabled ? '#e1e1e1' : 'white'};;
  background-color: ${props => props.disabled ? '#e1e1e1' : 'white'};
  }
`;

export default PrimaryButton;
