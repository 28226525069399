import React, { Fragment } from 'react';
import styled from 'styled-components';
import TextWrapper from '../theme/TextWrapper';
import { COLOR_ACCENT } from '../../constants/styles';

const AccentButton = (props) => (
  <Fragment>
    <ButtontWrapper {...props}>
      <ButtontTextWrapper>{props.children}</ButtontTextWrapper>
    </ButtontWrapper>
  </Fragment>
);

const ButtontWrapper = styled.button`
  border-radius: 10px;
  border-color: ${COLOR_ACCENT};
  border-style: solid;
  background-color: ${COLOR_ACCENT};
  padding: 10px 6px;
  width: 100%;
  margin-bottom: 10px;
  min-height: 50px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 5px;
`;
const ButtontTextWrapper = styled(TextWrapper)`
  font-size: 1.3em;
  font-weight: 800;
  color: white;
`;

export default AccentButton;
