import React, { useEffect, useState } from 'react';
import './App.css';
import approvalsService from './services/approvalsService';
import MosqueApproval from './models/MosqueApproval';
import ViewWithHeading, { AdditionalHeadingView } from './components/ViewWithHeading';
import BottomCardView from './components/BottomCardView';
import ViewWrapper from './components/theme/ViewWrapper';
import { COLOR_PRIMARY } from './constants/styles';
import AccentButton from './components/buttons/AccentButton';
import ErrorAlert from './components/alerts/ErrorAlert';
import {
  DetailsHeading,
  DetailsInfo,
  DetailsInfoBlock,
  DetailsInfoTitle,
} from './components/InformationDetailComponents';
import { Heading } from './components/ViewComponents';
import HeadingText from './components/text/HeadingText';
import SearchInput from './components/input/SearchInput';
import PrimaryButton from './components/buttons/PrimaryButton';
import SuccessAlert from './components/alerts/SuccessAlert';
import SubheadingText from './components/text/SubheadingText';
import styled from 'styled-components';
import { faMosque } from '@fortawesome/free-solid-svg-icons/faMosque';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons/faStickyNote';

function App() {
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [approvals : Array<MosqueApproval>, setApprovals] = useState([]);

  useEffect(() => {

    }, [key]);

  function getApprovals() {
      setError(null);setLoading(false);
    approvalsService.getApprovals(key)
    .then((data) => setApprovals(data))
    .catch((e) => setError(e.message))
    .finally(() => setLoading(false))
  }

  function approve(approval: MosqueApproval) {
      setError(null);
    setLoading(false);
    console.log("Approving " + approval);
    approvalsService.approve(approval.masjid_id, key)
    .then((data) => {
      setInfo(approval.name + " APPROVED");
      getApprovals();
    })
    .catch((e) => setError(e.message))
    .finally(() => setLoading(false))
  }

  function reject(approval: MosqueApproval) {
    setError(null);
  setLoading(false);
  console.log("Rejecting " + approval);
  approvalsService.reject(approval.masjid_id, key)
  .then((data) => {
    setInfo(approval.name + " REJECTED");
    getApprovals();
  })
  .catch((e) => setError(e.message))
  .finally(() => setLoading(false))
}

  return (
      <ViewWrapper style={{
      display: 'flex',
      flex: 1,
      height: '100%',
      backgroundColor: COLOR_PRIMARY,
      overflowY: "scroll"
    }}>
      <ViewWrapper style={{ height: '100%', flex: 1, display: 'flex' }}>
        <ViewWithHeading title={'Approvals'}>
          <AdditionalHeadingView style={{ paddingTop: "100px" }}>
            {info && <SuccessAlert>{info}</SuccessAlert>}
            <ViewWrapper style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%" }}>
              <HeadingText>Enter secret</HeadingText>
              <SearchInput type={"text"} value={key} style={{ height: "30px", width: "200px" }} onChange={(event) => setKey(event.target.value)} />
              <PrimaryButton style={{ marginTop: "30px" }} onClick={getApprovals}>Get Approvals</PrimaryButton>
            </ViewWrapper>
          </AdditionalHeadingView>
          {error && <ErrorAlert>{error}</ErrorAlert>}
          <BottomCardView style={{ alignItems: "center", display: "flex", minHeight: "max-content" }}>
            <ViewWrapper style={{ alignItems: "center", display: "block", }}>
              <HeadingText>Pending Approvals</HeadingText>
              {approvals.map(approval =>
                <View style={{ borderBottom: "solid 1px #e1e1e1", marginBottom: "10px", paddingBottom: "10px" }}>
                  <DetailsInfoBlock>
                    <DetailsInfoTitle icon={faMosque} title={"Mosque Name"} />
                    <DetailsInfo>{approval.name}</DetailsInfo>
                    <DetailsInfo>{approval.masjid_id}</DetailsInfo>
                  </DetailsInfoBlock>
                  <DetailsInfoBlock>
                    <DetailsInfoTitle icon={faLocationArrow} title={"Address"} />
                    {/*<MosqueMap mosqueWithSlots={mosque}/>*/}
                    <DetailsInfo>{approval.address}</DetailsInfo>
                  </DetailsInfoBlock>
                  <DetailsInfoBlock>
                    <DetailsInfoTitle icon={faStickyNote} title={"Contact"} />
                    <DetailsInfo>Email: {approval.email}</DetailsInfo>
                    <DetailsInfo>Phone: {approval.phone}</DetailsInfo>
                    <DetailsInfo>Url:   {approval.url}</DetailsInfo>
                  </DetailsInfoBlock>
                  <DetailsInfoBlock>
                    <DetailsInfoTitle icon={faStickyNote} title={"Charity Commission Details"} />
                    <div style={{ margin: "15px 0px 15px 0px" }}>
                      {approval.charity_registration_number === undefined ? <DetailsInfo style={{ color: "red", fontWeight: "bold" }}>No Charity Reg No.</DetailsInfo> : <DetailsInfo> Charity Reg No.: {approval.charity_registration_number}</DetailsInfo>}
                    </div>
                    <div style={{ marginBottom: "15px" }}>
                      {approval.trustee_name_1 === undefined ? <DetailsInfo style={{ color: "red", fontWeight: "bold" }}>No Primary Trustee Info</DetailsInfo> : <DetailsInfo> 1st Trustee Name: {approval.trustee_name_1}</DetailsInfo>}
                      {approval.trustee_phone_1 === undefined ? false : <DetailsInfo> 1st Trustee Phone: {approval.trustee_phone_1}</DetailsInfo>}
                      {approval.trustee_email_1 === undefined ? false : <DetailsInfo> 1st Trustee Email: {approval.trustee_email_1}</DetailsInfo>}
                    </div>
                    {approval.trustee_name_2 === undefined ? <DetailsInfo style={{ color: "red", fontWeight: "bold" }}>No Secondary Trustee Info</DetailsInfo> : <DetailsInfo> 2nd Trustee Name: {approval.trustee_name_2}</DetailsInfo>}
                    {approval.trustee_phone_2 === undefined ? false : <DetailsInfo> 2nd Trustee Phone: {approval.trustee_phone_2}</DetailsInfo>}
                    {approval.trustee_email_2 === undefined ? false : <DetailsInfo> 2nd Trustee Email: {approval.trustee_email_2}</DetailsInfo>}
                  </DetailsInfoBlock>
                  <DetailsInfoBlock>
                    <DetailsInfoTitle icon={faStickyNote} title={"Additional Information"} />
                    <DetailsInfo>{approval.notes}</DetailsInfo>
                  </DetailsInfoBlock>
                  <AccentButton style={{ marginTop: "10px" }} onClick={() => approve(approval)}>Approve</AccentButton>
                  <AccentButton style={{ marginTop: "10px" }} onClick={() => reject(approval)}>Reject</AccentButton>
                </View>
              )}
            </ViewWrapper>
          </BottomCardView>
        </ViewWithHeading>
      </ViewWrapper>
    </ViewWrapper>
  );
}



const View = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
`;


export default App;
