class MosqueApproval {
  masjid_id: string;
  slug: string;
  name: string;
  address: string;
  email: string;
  phone: string;
  url: string;
  charity_registration_number: string;
  notes: string;
  trustee_name_1: string;
  trustee_phone_1: string;
  trustee_email_1: string;
  trustee_name_2: string;
  trustee_phone_2: string;
  trustee_email_2: string;
  trustee_details_known: boolean;
  pending: boolean;

  constructor(
    masjid_id: string, slug: string, name: string, address: string,
    email: string, phone: string, url: string, charity_registration_number: string, notes: string,
    trustee_name_1: string, trustee_phone_1: string, trustee_email_1: string,
    trustee_name_2: string, trustee_phone_2: string, trustee_email_2: string,
    trustee_details_known: boolean, pending: boolean
  ) {
    this.masjid_id = masjid_id;
    this.slug = slug;
    this.name = name;
    this.address = address;
    this.email = email;
    this.phone = phone;
    this.url = url;
    this.charity_registration_number = charity_registration_number;
    this.trustee_name_1 = trustee_name_1;
    this.trustee_phone_1 = trustee_phone_1;
    this.trustee_email_1 = trustee_email_1;
    this.trustee_name_2 = trustee_name_2;
    this.trustee_phone_2 = trustee_phone_2;
    this.trustee_email_2 = trustee_email_2;
    this.trustee_details_known = trustee_details_known;
    this.pending = pending;
  }
}

export default MosqueApproval;
