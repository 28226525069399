import React, {Fragment} from 'react';
import TextWrapper from '../theme/TextWrapper';
import styled from 'styled-components';
import {
  COLOR_TEXT_DARK,
  COLOR_TEXT_LIGHT,
  MAX_MOBILE_WIDTH,
} from '../../constants/styles';

function SubheadingText(props : {isDark: boolean}) {
  return (
      <Fragment>
        <Text {...props}/>
      </Fragment>
  )
}

const Text = styled(TextWrapper)`
  font-weight: 800;
  font-size: 1.2em;
  padding-bottom: 7px;
  color: ${props => props.isDark ? COLOR_TEXT_DARK : COLOR_TEXT_LIGHT};
  @media (min-width: ${MAX_MOBILE_WIDTH}) {
  font-size: 2em;
  }
`;

export default SubheadingText;