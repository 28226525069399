import ViewWrapper from './theme/ViewWrapper';
import React from 'react';
import Header from './Header';
import {COLOR_PRIMARY} from '../constants/styles';

type Props = {
  title: string
}

function ViewWithHeading(props : Props) {
  return (
      <ViewWrapper style={{height: "100%",flex: 1, display: "flex", flexDirection: "column"}}>
        <Header title={props.title}/>
        {props.children}
      </ViewWrapper>
  )
}

export function AdditionalHeadingView(props) {
  return (
      <ViewWrapper style={{display: "flex", flexDirection: "column", backgroundColor: COLOR_PRIMARY, padding: "10px 20px", ...props.style}}>
        {props.children}
      </ViewWrapper>
  )
}


export default ViewWithHeading;