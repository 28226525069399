import React from 'react';
import ViewWrapper from '../theme/ViewWrapper';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {COLOR_TEXT_DARK} from '../../constants/styles';

const Input = styled.input`
  background-color: white;
  border: none;
  flex: 1;
`;

const SearchInputWrapper = styled(ViewWrapper)`
  background-color: white;
  color: ${COLOR_TEXT_DARK};
  border-radius: 10px;
  border-style: solid;
  border-color: white;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

type Props = {
  placeholder: string,
  onChange: () => void,
}

function SearchInput(props: Props) {
  return (
      <SearchInputWrapper>
        <FontAwesomeIcon icon={faSearch} color={COLOR_TEXT_DARK} size={"sm"} style={{marginRight: "5px"}}/>
        <Input {...props}/>
      </SearchInputWrapper>
  )
}

export default SearchInput;