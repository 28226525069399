import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../constants/styles';

const TextWrapper = styled.div`
  font-family: 'Nunito', sans-serif;
  @media (min-width: ${MAX_MOBILE_WIDTH}) {
  }
`;

export default TextWrapper;
