import axios from 'axios';
import {API_URL} from '../config/apiConfig';
import {
  getDataFromResponse, isResponse2XX,
  responseErrorHandler,
} from '../config/axiosConfig';
import MosqueApproval from '../models/MosqueApproval';

const ENDPOINT_PATH = "/private/masjid";

class ApprovalService {

  approve = async (id: string, key: string) => {
    const response = await axios.post(`${API_URL + ENDPOINT_PATH}/${id}/approve`, null, {
      headers: {
        'x-api-key': key
      }
    })
    .catch ((err) => responseErrorHandler(err));
    return isResponse2XX(response);
  };

  reject = async (id: string, key: string) => {
    const response = await axios.delete(`${API_URL + ENDPOINT_PATH}/${id}/reject`, {
      headers: {
        'x-api-key': key
      }
    })
    .catch ((err) => responseErrorHandler(err));
    return isResponse2XX(response);
  };

  getApprovals = async (key: string) : Array<MosqueApproval> => {
    const response = await axios.get(API_URL + ENDPOINT_PATH, {
      params: {
        'awaiting-approval': true,
      },
      headers: {
        'x-api-key': key
      }
    })
    .catch ((err) => responseErrorHandler(err));

    const data = getDataFromResponse(response);
    return data.map(obj => Object.assign(new MosqueApproval(), obj));
  };


}

export default new ApprovalService();