import styled from 'styled-components';
import ViewWrapper from './theme/ViewWrapper';
import { COLOR_PRIMARY, MAX_MOBILE_WIDTH } from '../constants/styles';
import React from 'react';

export const BlurredViewComponent = styled(ViewWrapper)`
  opacity: 0.4 !important;
  z-index: 50;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background-color: #3a3a3a;
`;

export const BorderedBox = styled(ViewWrapper)`
  background-color: ${(props) => props.backgroundColor || 'white'};
  border-color: ${(props) => props.backgroundColor || 'white'};
  border-style: solid;
  border-radius: 20px;
  padding: 10px 15px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  margin-bottom: 25px;
`;

export const HeadingWrapper = styled(ViewWrapper)`
  background-color: ${(props) => props.color || COLOR_PRIMARY};
  height: 200px;
  width: 100%;
  border-bottom-right-radius: 100px;
  position: fixed;
  top: auto;
  z-index: -10;
  @media (min-width: ${MAX_MOBILE_WIDTH}) {
    height: 400px;
  }
`;

export const Heading = () => (
  <HeadingWrapper>
    <ViewWrapper
      style={{
        padding: '20px 10px',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}></ViewWrapper>
  </HeadingWrapper>
);
